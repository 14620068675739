<template>
  <v-container>
    <div>
      <h3>{{ $t("message.order-detail-page-title") }}</h3>
    </div>

    <OrderDetailInfo v-if="getOrderDetails" :orderDetail="getOrderDetails" />

    <v-pagination
      v-if="!orderDetail_isLength"
      v-model="page"
      :length="orderDetail_lengthPage"
      @input="changePage"
      depressed
      :total-visible="7"
    ></v-pagination>

    <div v-if="getOrderDetails">
      <v-data-table
        :headers="headers"
        :items="details.data"
        disable-sort
        :items-per-page="orderDetailsHeader.per_page"
        :hide-default-footer="true"
      >
        <!-- top pagination extend -->
        <!-- <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :items-per-page-text="$t('message.rows-per-page')"
            class="elavation-0 v-data-footer-top"
          />
        </template> -->

        <template v-slot:headers="{ props }">
          <th class="custom-th text-center">
            {{ props.headers[0].text }}
          </th>
          <th class="custom-th text-center">
            {{ props.headers[1].text }}
          </th>
          <th class="custom-th text-center">
            {{ props.headers[2].text }}
          </th>
          <th class="custom-th text-center">
            {{ props.headers[3].text }}
          </th>
          <th class="custom-th text-center">
            {{ props.headers[4].text }}
          </th>
          <th class="custom-th text-center">
            {{ props.headers[5].text }}
          </th>
        </template>
      </v-data-table>
    </div>

    <div class="d-flex flex-row-reverse mt-12 me-0" tile>
      <div class="m-2">
        <v-btn color="grey" @click="$router.go(-1)">
          {{ $t("message.back-button") }}
        </v-btn>
      </div>
    </div>

    <div class="" v-if="getOrderDetails !== undefined">
      <div
        class="d-flex flex-row justify-end"
        v-if="getOrderDetails.subtotal > 0"
      >
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.subtotal") }} :</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{ formatDecWithoutComa(getOrderDetails.subtotal) }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-end"
        v-if="getOrderDetails.subtotal_exclude_taxable_value_8 > 0"
      >
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.subtotal-exclude-taxable-value-8") }} :</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{
            formatDecWithoutComa(
              getOrderDetails.subtotal_exclude_taxable_value_8
            )
          }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-end"
        v-if="getOrderDetails.subtotal_consumption_tax_8 > 0"
      >
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.subtotal-consumption-tax-8") }} :</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{
            formatDecWithoutComa(getOrderDetails.subtotal_consumption_tax_8)
          }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-end"
        v-if="getOrderDetails.subtotal_exclude_taxable_value_10 > 0"
      >
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.subtotal-exclude-taxable-value-10") }} :</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{
            formatDecWithoutComa(
              getOrderDetails.subtotal_exclude_taxable_value_10
            )
          }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-end"
        v-if="getOrderDetails.subtotal_consumption_tax_10 > 0"
      >
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.subtotal-consumption-tax-10") }} :</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{
            formatDecWithoutComa(getOrderDetails.subtotal_consumption_tax_10)
          }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-end"
        v-if="getOrderDetails.subtotal_include_taxable_value_8 > 0"
      >
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.subtotal-include-taxable-value-8") }} :</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{
            formatDecWithoutComa(
              getOrderDetails.subtotal_include_taxable_value_8
            )
          }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-end"
        v-if="getOrderDetails.subtotal_consumption_taxable_8 > 0"
      >
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.subtotal-consumption-taxable-8") }}:</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{
            formatDecWithoutComa(getOrderDetails.subtotal_consumption_taxable_8)
          }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-end"
        v-if="getOrderDetails.subtotal_include_taxable_value_10 > 0"
      >
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.subtotal-include-taxable-value-10") }} :</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{
            formatDecWithoutComa(
              getOrderDetails.subtotal_include_taxable_value_10
            )
          }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-end"
        v-if="getOrderDetails.subtotal_consumption_taxable_10 > 0"
      >
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.subtotal-consumption-taxable-10") }} :</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{
            formatDecWithoutComa(
              getOrderDetails.subtotal_consumption_taxable_10
            )
          }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-end"
        v-if="getOrderDetails.subtotal_non_taxable_value > 0"
      >
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.subtotal-non-taxable-value") }} :</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{
            formatDecWithoutComa(getOrderDetails.subtotal_non_taxable_value)
          }}
        </div>
      </div>
      <div class="d-flex flex-row justify-end">
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.delivery-fee-display") }} :</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{ formatDecWithoutComa(getOrderDetails.delivery_fee_display) }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-end"
        v-if="
          getOrderDetails.total_amount_w_delivery_fee_per_store !== undefined
        "
      >
        <div
          class="d-flex flex-column col-10 col-md-10 align-end"
          style="font-size: 14px; line-height:  1px"
        >
          <span>{{ $t("message.total-amount-delivery-fee-store") }} :</span>
        </div>
        <div
          class="d-flex flex-column col-md-2"
          style="font-size: 14px; line-height:  1px"
        >
          ¥{{
            formatDecWithoutComa(
              getOrderDetails.total_amount_w_delivery_fee_per_store
            )
          }}
        </div>
      </div>
    </div>

    <div style="bottom:0px;width:100%;margin-bottom:2%">
      <OrderDetailDeliveryInfo
        v-if="orderDetail.delivered_from"
        :orderDetailAdressFrom="orderDetail.delivered_from"
        :orderDetailAdressTo="orderDetail.delivered_to"
        :order="getOrderDetails"
        
      />
    </div>
  </v-container>
</template>

<script>
import OrderDetailInfo from "@/components/OrderDetailInfo";
import OrderDetailDeliveryInfo from "@/components/OrderDetailDeliveryInfo";
import formatDecimal from "../helpers/formatDecimal";
import formatDecWithoutComa from "../helpers/formatDecWithoutComa";
import moneyFormat from "../helpers/moneyFormat";
export default {
  name: "OrderDetail",
  components: {
    OrderDetailInfo,
    OrderDetailDeliveryInfo,
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    orderDetail_isLength() {
      return this.$store.state.order_module.orderDetails.is_length;
    },
    orderDetail_lengthPage() {
      return this.$store.state.order_module.orderDetails.length_page;
    },
    orderDetailsHeader() {
      // console.log(this.$store.state.order_module.orderDetailsHeader.per_page, 'perpage');
      let checkPer_page = this.$store.state.order_module.orderDetailsHeader
        .per_page;
      if (checkPer_page == undefined) {
        return (checkPer_page = 10);
      } else {
        return this.$store.state.order_module.orderDetailsHeader.per_page;
      }
    },
    orderDetail() {
      let orderDetail = this.$store.state.order_module.orderDetailResult;
      return orderDetail;
    },
    getOrderDetails() {
      return this.$store.state.order_module.orderDetails;
    },
    details() {
      let getDetail = this.$store.state.order_module.details;

      if (getDetail.data) {
        getDetail.data.map((order) => {
          order.price_display_peritem = moneyFormat(
            parseInt(order.price_display_peritem)
          );
          order.price_display = moneyFormat(parseInt(order.price_display));
        });
      }
      return getDetail;
    },
    headers() {
      return [
        {
          text: this.$t("message.item-name"),
          align: "start",
          value: "item_name",
        },
        { text: this.$t("message.weight"), value: "weight" },
        { text: this.$t("message.qty"), value: "qty" },
        { text: this.$t("message.price"), value: "price_display_peritem" },
        { text: this.$t("message.total-price"), value: "price_display" },
        { text: this.$t("message.tax"), value: "tax_type_display" },
      ];
    },
  },
  created() {
    this.dispatchOrderDetail();
  },
  methods: {
    async dispatchOrderDetail() {
      let id = this.$router.history.current.params.id;
      let pageInit = parseInt(this.$store.state.order_module.orderDetails.page);
      this.page = parseInt(this.$store.state.order_module.orderDetails.page);
      await this.$store.dispatch("order_module/fetchOrderDetail", {
        id: id,
        page: pageInit,
      });
    },
    formatDecimal(money) {
      return formatDecimal(money);
    },
    formatDecWithoutComa(money) {
      return formatDecWithoutComa(money);
    },
    changePage(event) {
      let id = this.$router.history.current.params.id;
      return this.$store.dispatch("order_module/fetchOrderDetail", {
        id: id,
        page: event,
      });
    },
  },
};
</script>

<style>
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}
</style>
