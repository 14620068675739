<template>
  <v-card class="d-flex flex-wrap mb-5" flat tile>
    <div class="d-flex flex-column my-10 w-100">
      <div style="margin-left:-9px;font-weight:bold">【備考】</div>
      <div style="text-align: justify" v-if="order.order_shipping_type == 1">
        置き配を希望する
      </div>
      <div style="text-align:justify; white-space: pre-line">
        {{ order.notes }}
      </div>
    </div>
    <v-row style="width:100%">
      <v-col md="3" xs="1" cols="6" style="font-weight: bold"
        >{{ $t("message.label-customer") }}:</v-col
      >
      <v-col md="9" xs="1" cols="6">
        <div style="font-weight:500">
          <a href="" @click.prevent="toCustomerDetail(orderDetailAdressTo)">
            {{ orderDetailAdressTo.customer_name }} ({{
              orderDetailAdressTo.customer_name_furigana
            }})</a
          >
        </div>
        <div>
          {{ orderDetailAdressTo.customer_phone }}
        </div>
        <div>
          {{ orderDetailAdressTo.customer_email }}
        </div>
      </v-col>
    </v-row>

    <v-row style="width:100%">
      <v-col md="3" xs="1" cols="6">
        <span style="font-weight: bold"
          >{{ $t("message.delivery-from") }}:
        </span>
      </v-col>
      <v-col md="9" xs="1" cols="6">
        <div style="font-weight: 500">
          {{ orderDetailAdressFrom.store_name }}
        </div>
        <div>
          〒{{ formatPostalcode(orderDetailAdressFrom.postal_code) }}
          {{ orderDetailAdressFrom.prefecture }}
          {{ orderDetailAdressFrom.city }} {{ orderDetailAdressFrom.district }}
        </div>
        <div>
          {{ orderDetailAdressFrom.store_address }}
        </div>
      </v-col>
    </v-row>
    <v-row style="width:100%">
      <v-col md="3" xs="1" cols="6">
        <span style="font-weight: bold">{{ $t("message.delivery-to") }}: </span>
      </v-col>
      <v-col md="9" xs="1" cols="6">
        <div>
          <span style="font-weight: 500">{{
            orderDetailAdressTo.address_name
          }}</span>
        </div>
        <div>
          {{ orderDetailAdressTo.address_raw }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "OrderDetailDeliveryInfo",
  data() {
    return {
      detailOrder: {},
    };
  },
  props: {
    orderDetailAdressFrom: Object,
    orderDetailAdressTo: Object,
    order: Object
  },
  methods: {
    formatPostalcode(postalcode) {
      if (postalcode != null && postalcode != undefined) {
        let convertStr = postalcode.toString();
        let newPostcode = convertStr.slice(0, 3) + "-" + convertStr.slice(3);
        return newPostcode;
      }
    },
    toCustomerDetail(order) {
      this.$router.push(`/customer/detail/${order.customer_id}`);
    },
  },
};
</script>

<style></style>
