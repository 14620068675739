<template>
  <v-card class="d-flex flex-wrap mb-5" flat tile>
    <v-row>
      <v-col md="2" xs="1" cols="6">
        <span style="font-weight: bold">{{$t('message.order-number')}}: </span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span>{{orderDetail.order_no}}</span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span style="font-weight: bold">{{$t('message.invoice')}}: </span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span>{{orderDetail.invoice_no}}</span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span style="font-weight: bold">{{$t('message.amount')}}: </span>
      </v-col>
      <v-col md="2" xs="1" cols="6" v-if="orderDetail.total_amount_w_delivery_fee_per_store !== undefined">
        <span>¥{{ formatDecimal(orderDetail.total_amount_w_delivery_fee_per_store) }}</span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span style="font-weight: bold">{{$t('message.order-date')}}: </span>
      </v-col>
      <v-col cols="2">
        <span>{{orderDetail.created_at}}</span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span style="font-weight: bold">{{$t('message.status')}}: </span>
      </v-col>
      <v-col md="auto" xs="1" cols="6">
        <span>{{orderDetail.status}}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import formatDecimal from '../helpers/formatDecimal'
export default {
  name: "OrderDetailInfo",
  data() {
    return {
      detailOrder: {}
    }
  },
  props: {
    orderDetail: Object
  },
  methods: {
    formatDecimal(money) {
      return formatDecimal(money)
    }
  }
};
</script>

<style></style>
